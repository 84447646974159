<template>
  <el-dialog title="完结工单" width="500px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfData"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        cai_gou_stock_id: '',
        ren_wu_status: '已完结',
        shi_ji_sheng_chan_count: ''
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'text',
          title: '实际生产数量',
          field: 'shi_ji_sheng_chan_count',
          required: true
        }
      ],
      rules: {
        shi_ji_sheng_chan_count: [{ required: true, message: '请输入实际生产数量' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.selfData.cai_gou_stock_id = this.data.cai_gou_stock_id
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          let isPassCount = Number(this.selfData.shi_ji_sheng_chan_count)
          if (!isPassCount) {
            this.$message.error('实际生产数量必须>0')
            return
          }

          this.$api({
            method: 'post',
            url: '/admin/gcvip/gongDan/doOk',
            data: this.selfData
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('操作成功')
              this.$emit('success')
              this.popupCancel()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
